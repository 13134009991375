<template>
    <div id="inventory1" class="container-fluid">
        <titles title="Inventario" :subtitle="tab_active" :othertitle="titletab_active"></titles>
        <tabs :array_tabs="tabs_array" @changeTab="controlTabs($event)"></tabs>
        <title-tabs :array_titletabs="titletabs_array" @changeTitle="controlTitles($event)"></title-tabs>

        <div  v-if="tab_active == 'Movimientos'">
            <buy-order v-if="titletab_active == 'Generar orden de compra'"></buy-order>
            <buy-order-gestor v-if="titletab_active == 'Ordenes de compra'"></buy-order-gestor>
            <physical-inventory v-if="titletab_active == 'Inventario físico'"></physical-inventory>
            <warehouse-transfer v-if="titletab_active == 'Traspaso a almacenes'"></warehouse-transfer>
        </div>
        <div  v-if="tab_active == 'Catálogos'">
            <losses v-if="titletab_active == 'Motivo de mermas'"></losses>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../components/Titles.vue'
    import Tabs from '../components/Tabs.vue'
    import TitleTabs from '../components/TitleTabs.vue'

    //Icons
    import icon_movements from '../assets/icons/icon_movements.png'
    import icon_book from '../assets/icons/icon_book.png'

    //Views
    //Movements
    import Buyorder from "./Inventory/Movements/Buyorder.vue";
    import BuyorderGestor from "./Inventory/Movements/BuyorderGestor.vue"
    import Physicalinventory from "./Inventory/Movements/Physicalinventory.vue";
    import Warehousetransfer from "./Inventory/Movements/Warehousetransfer.vue";
    //Catalogs
    import Losses from "./Catalogues/Warehouses/Losses.vue";

export default {
    name: 'Inventory',
    components: {
            'titles': Titles,
            'tabs': Tabs,
            'title-tabs': TitleTabs,
            'buy-order': Buyorder,
            'buy-order-gestor': BuyorderGestor,
            'physical-inventory': Physicalinventory,
            'warehouse-transfer': Warehousetransfer,
            'losses': Losses,
        },

    data() {
        return {
            tabs_array:[
                { img: icon_movements,  name: 'Movimientos', selected: true, key: 'movements' }
            ],
            title_movements: [
                { name: 'Generar orden de compra', selected: true },
                { name: 'Ordenes de compra', selected: false },
                { name: 'Inventario físico', selected: false } ,
                { name: 'Traspaso a almacenes', selected: false }
            ],

            titletabs_array:[],
            tab_active: null,
            titletab_active: 'Orden de compra'
        };
    },
    methods: {
            async controlTabs(event) {
                this.tab_active = event
                for (let i in this.tabs_array) {
                    if(this.tabs_array[i].selected){
                        this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                    }
                }
                for (let i in this.titletabs_array) {
                    if(this.titletabs_array[i].selected){
                        this.titletab_active = this.titletabs_array[i].name
                    }
                }
            },
            async controlTitles(event) {
                this.titletab_active = event
            },
    },
    created(){
        for (let i in this.tabs_array) {
            if(this.tabs_array[i].selected){
                this.tab_active = this.tabs_array[i].name
                this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
            }
        }
        for (let i in this.titletabs_array) {
            if(this.titletabs_array[i].selected){
                this.titletab_active = this.titletabs_array[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>