<template>
    <div class="container-fluid bg-white shadow-sm p-2 mb-5 rounded border-box text-dark row">


        <div class="col-12 my-3">
            <div class="row bg-lightgray align-items-end py-3">

                <div class="col-4 opensans-bold">
                    <ki-input @input="searchItem" label="Buscar:"
                        v-model="search_query"
                        placeholder="ej: Manchado"
                        type="text"
                        full>
                    </ki-input>
                </div>

                <div class="col-4">
                    <button @click.stop="(loss_modal = true)"
                        class="btn btn-white pills"
                        type="button">
                        <img :src="add_icon" />
                        Añadir motivo
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 text-center" v-if="!loading && fetch_error">
            <p>
                Ha ocurrido un error intente más tarde
            </p>
        </div>

        <div class="col-12 text-center" v-if="no_items">
            <p>
                No hay resultados para esta busqueda
            </p>
        </div>

        <div class="col-12 mb-3" v-if="(!loading && !fetch_error && losses_table_data.length > 0)">
            <ki-table :data="losses_table_data"
                :allow_headers="losses_table_headers"
                no_pagination color_body="white" color_headers="white">
                <template v-slot:Editar="data">
                    <button @click.stop="selectLoss('edit', data.item.id_reason_losses)"
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_write"/>
                    </button>
                </template>
                <template v-slot:Borrar="data">
                    <button @click.stop="selectLoss('delete', data.item.id_reason_losses)"
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_delete_red" />
                    </button>
                </template>
            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-----Modal------->
        <ki-modal v-if="loss_modal">
            <div class="row bg-white position-relative text-dark" style="border-radius: 15px;">
                <button @click.stop="loss_name = null; loss_modal = false; loss_selected_id = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0,0.4)"
                    type="button">
                    X
                </button>

                <div class="col-12">
                    <p v-if="!loss_selected_id">
                        Añadir motivo de merma
                    </p>
                    <p v-if="loss_selected_id">
                        Editar motivo de merma
                    </p>
                </div>

                <div class="col-12 opensans-bold">
                    <ki-input label="Nombre:"
                        placeholder="ej: roto"
                        v-model="loss_name"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_loss_name">
                        {{ error_loss_name}}
                    </span>
                </div>

                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click.stop="loss_name = null; loss_modal = false; loss_selected_id = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="createEditLoss"
                        class="btn btn-primary text-white ml-3 pills"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 15px">
                <button @click.stop="loss_selected_id = null; delete_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0,0.4)"
                    type="button">
                    X
                </button>

                <div class="col-12 text-center p-3 opensans-bold">
                    <p>
                        ¿Esta seguro de eliminar este motivo de merma?
                    </p>
                </div>

                <div class="col-12 p-3 d-flex justify-content-center">
                    <button @click.stop="loss_selected_id = null; delete_modal = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteLoss"
                        class="btn btn-danger pills text-white ml-3"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png'
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters } from 'vuex';

    export default {
        name: 'ki-losses',

        data() {
            return {
                icon_write,
                icon_delete_red,
                add_icon,
                loading: false,
                fetch_error: false,
                loss_modal: false,
                delete_modal: false,
                losses_table_headers: [
                    {key: 'reason_losses_name', label: 'Motivo de merma'},
                    {key: 'Editar', label: 'Editar', width: 60},
                    {key: 'Borrar', label: 'Borrar', width: 60}
                ],
                losses_table_data: [],
                loss_name: null,
                error_loss_name: null,
                loss_selected_id: null,
                no_items: false,
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            };
        },

        computed: {
            ...mapGetters({
                losses: 'losses/getLosses',
                loss: 'losses/getLoss',
                total_pages: 'losses/getTotalPages',
                total_rows: 'losses/getTotalRows'
            })
        },

        methods: {
            //helpers
            async selectLoss(type, id) {
                switch(type){
                    case 'edit':
                        this.loss_selected_id = id
                        await this.$store.dispatch('losses/viewLoss', { id_reason_losses: this.loss_selected_id})
                        this.loss_name = this.loss.reason_losses_name
                        this.loss_modal = true
                        break;
                    case 'delete':
                        this.loss_selected_id = id
                        this.delete_modal = true
                        break;
                }
            },

            //losses CRUD
            async createEditLoss(){
                this.error_loss_name = null

                let is_complete = true

                if(_.isNull(this.loss_name) || _.isEmpty(this.loss_name)) {
                    is_complete = false
                    this.error_loss_name = '*Campo obligatorio'
                    return
                }

                if(is_complete) {

                    const payload = {
                        name: this.loss_name
                    }

                    if(this.loss_selected_id) {

                        let data = {
                            body: payload,
                            query: {
                                id_reason_losses: this.loss_selected_id
                            }
                            
                        }
                     
                        await this.$store.dispatch('losses/editLoss', data)
                        await this.navigate()
                        this.loss_modal = false
                        this.loss_name = null
                        this.loss_selected_id = null
                        return
                    }

                    else {
                        await this.$store.dispatch('losses/addLoss', payload)
                        await this.navigate()
                        this.loss_modal = false
                        this.loss_selected_id = null
                        this.loss_name = null
                    }
                }
            },
            async deleteLoss(){
                await this.$store.dispatch('losses/deleteLoss', { id_reason_losses: this.loss_selected_id})
                await this.navigate()
                this.delete_modal = false
                this.loss_selected_id = null
                
            },
            async searchItem(){
                await this.navigate();
            },
            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }

	            await this.$store.dispatch('losses/listLosses', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
	            
                if(!this.losses) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.losses_table_data = this.losses
                }
                console.log(this.losses)
                this.loading = false
                        
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },

        async mounted() {
            try {
                //losses list
                await this.navigate()
                this.fetch_error = false
            }

            catch(error) {
                console.error(error)
                this.losses_table_data = []
                this.loading = false
                this.fetch_error = true
            }   
        },

    };
</script>

<style lang="scss" scoped>

</style>