<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box row">

        <div class="col-12 p-0 my-3">
            <div class="row bg-lightgray py-4">
                <div class="col-4 p-0" style="max-width: 200px">
                    <ki-input @input="navigate" label="Ingrese busqueda:"
                        type="text"
                        placeholder="Buscar"
                        v-model="search_query"
                        class="opensans-bold pt-1 pl-2"
                        full>
                    </ki-input>    
                </div>

            </div>
        </div>
        

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 text-center" v-if="(!loading && fetch_error)" >
            <p style="font-size: 24px">
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 text-center text-dark" v-if="(no_results)" >
            <p style="font-size: 24px">
                Sin Resultados para esta búsqueda
            </p>
        </div>

        <div class="col-12 p-0 m-0 border-top border-left mt-2" v-if="(!loading && !fetch_error && data_table_inventory.length > 0)">
            <ki-table :data="data_table_inventory"
                :allow_headers="allow_headers_inventory" 
                no_pagination color_headers="primary" color_body="white"
                color_headers_text="white" border_headers="primary">
            </ki-table>
        </div>

        <div class="col-12 p-0 my-2" v-else>
            <p class="text-dark opensans-bold text-center">
                No hay productos en el inventario.
            </p>
        </div>

        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página:
                    </label>
                    <ki-input @keyup.enter="navigate"
                              v-model="rows"
                              :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                                variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                              v-model="current_page"
                              full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                        <b-icon icon="arrow-right"
                                variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
    //helpers
    import { mapGetters } from 'vuex';

    export default {
        name: 'ki-physicalinventory',

        data() {
            return {
                allow_headers_inventory: [
                    {key: 'upc', label: 'Código'},
                    {key: 'product_name', label: 'Producto'},
                    {key: 'quantity', label: 'Existencias'},
                    {key: 'sub_category_name', label: 'Subcategoria'},
                    {key: 'vendor_name', label: 'Provedor'},
                    {key: 'category_name', label: 'Categoria'},
                    {key: 'retail_price', label: 'Precio menudeo'},
                    {key: 'sell_price', label: 'Precio mayoreo'},
                    {key: 'unit_cost', label: 'Costo'}
                ],
                data_table_inventory: [],
                loading: true,
                fetch_error: false,
                search_type: 1,
                no_results: false,
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            };
        },
        computed: {
            ...mapGetters({
                products: 'product_transfers_module/getInventory',
                total_pages: 'product_transfers_module/getTotalPages',
                total_rows: 'product_transfers_module/getTotalRows'
            })
        },
        methods: {
            searchProduct() {
            },
            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }


                await this.$store.dispatch('product_transfers_module/listInventoryProducts', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})


                if(!this.products) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.data_table_inventory = this.products
                }

                this.loading = false
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },

        async mounted() {
            try {
                await this.navigate()
                this.loading = false
            }

            catch(error) {
                this.loading = false
                this.fetch_error = true
                this.data_table_inventory = []
            }

        }
    };
</script>

<style lang="scss" scoped>


</style>