<template>
    <div id="Inventory" class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box">

        <div class="row">
            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>
        </div>

        <div class="row my-3" v-if="!loading">


            <div class="col-6 p-2 bg-lightgray  py-3">
                <div class="row align-items-end pt-xl-2">

                    <div class="col-xl-6 col-xxl-4 pl-2">
                        <ki-input  @keyup.enter="searchProductByCode" label="Código:"
                            class="opensans-bold" 
                            placeholder="202342233"              
                            v-model="product_code"
                            full>
                        </ki-input>
                        <span class="text-danger" v-if="error.product_code">
                            {{ error.product_code }}
                        </span>
                    </div>

                    <div class="col-xl-6 col-xxl-4 pl-md-2 p-0">
                        <ki-select @change="setProducts(provider_id)" label="Proveedor:"
                            class="opensans-bold"
                            hidden_option="Seleccione"
                            :options="providers_options"
                            v-model="provider_id"
                            full>
                        </ki-select>
                        <span class="text-danger" v-if="error.provider_id">
                            {{ error.provider_id}}
                        </span>
                    </div>

                    <div class="col-xl-6 col-xxl-4 pl-md-2 pl-xl-2">
                        <ki-select @change="checkProduct(product_id)" label="Producto:"
                            v-model="product_id" 
                            class="opensans-bold"
                            hidden_option="Seleccione"
                            :options="products_options"
                            full>
                        </ki-select>
                        <span class="text-danger" v-if="error.product_id">
                            {{ error.product_id}}
                        </span>
                    </div>

                    <div class="col-lg-3 col-xxl-3 p-0 pl-xxl-2 pt-2">
                        <ki-input label="Cantidad:"
                            class="opensans-bold"
                            v-model="quantity"
                            type="number"
                            full>
                        </ki-input>
                        <span class="text-danger"  v-if="error.quantity">
                            {{ error.quantity}}
                        </span>
                    </div>

                    <div class="col-lg-3 col-xxl-3 p-0 pl-2" style="max-width: 110px">
                        <div class="row" >
                            <div class="col-12 p-0">
                                <button @click.stop="quantity--"
                                    class="btn bg-white border p-0 mr-2"
                                    style="width: 35px;height: 35px"
                                    :disabled="(quantity <= 0)"
                                    type="button">
                                    <img :src="less_icon" />
                                </button>
                                <button @click.stop="quantity++"
                                    class="btn p-0"
                                    style="background:#044281; width: 35px; height: 35px"
                                    type="button">
                                    <img :src="add_icon"
                                        style="background-image: contain" />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-6 px-xxl-0 py-2 opensans-bold bg-lightgray py-3">
                <div class="row align-items-end pt-2">

                    <div class="col-xl-6 col-xxl-4 p-0">
                        <ki-input label="Costo:"
                            v-model="cost"
                            disabled
                            full>
                        </ki-input>
                        <span class="text-danger" v-if="error.cost">
                            {{ error.cost}}
                        </span>
                    </div>

                    <div class="col-xl-6 col-xxl-4 p-0 pl-xl-2">
                        <ki-input label="Importe:"
                            v-model="amount"
                            disabled
                            full>
                        </ki-input>
                        <span class="text-danger" v-if="error.amount">
                            {{ error.amount}}
                        </span>
                    </div>

                    <div class="col-6 col-xxl-4 p-0 d-flex align-items-end pl-2 pt-2 pt-xxl-0">
                        <button @click.stop="addProduct"
                            class="btn btn-white pills"
                            style="min-width: 130px;"
                            type="button">
                            <img :src="add_icon_blue" />
                            Agregar
                        </button>
                    </div>

                </div>
            </div>

            <div class="col-12 p-0 py-3">
                <p class="m-0 text-primary opensans-bold">
                    Productos en esta orden
                </p>
            </div>

            <div class="col-12 p-0">
                <ki-table :data="product_table_data" v-if="(product_table_data.length > 0)"
                    :allow_headers="product_table_headers"
                    no_pagination color_headers="white" color_body="white">
                    <template v-slot:Borrar="data">
                            <button @click.stop="removeProduct(data.item)"
                                class="btn  pills"
                                type="button">
                                <img :src="trash_icon"/>
                            </button>
                    </template>
                </ki-table>
            </div>

            <div class="col-12 d-flex justify-content-end align-items-end">

                <button @click.stop="(clear_modal = true)"
                    class="btn btn-white pills mt-3 ml-3"
                    type="button">
                    <img :src="trash_icon" />
                    Limpiar todo
                </button>
            </div>

        </div>
        
        
        <!--------White fixed bar------>
        <div class="row position-fixed bg-white  all_transitions" 
            style="min-height: 90px; bottom: 0px; right: 0px;"
            :style="`left: ${ show_label ? '250px' : '90px'} ;width: ${ show_label ? '84%' : '94%'}`">

            <div class="col-12 p-0">
                <div class="row align-items-center p-0 h-100">

                    <div class="col-6 px-0 pb-2">
                        
                        <button @click.stop="historial_modal = true"
                            class="btn btn-white pills ml-2"
                            style="width: 225px; height: 40px;"
                            type="button">
                            <img :src="historial_icon"/>
                            Historial de ordenes
                        </button>

                            
                    </div>

                    <div class="col-6 px-0">
                        <div class="row px-0">
                            <div class="col-12 col-xxl-5">
                                <div class="row p-0" style="min-width: 280px; height: 70px;">
                                    <div class="col-12 opensans-bold p-0">
                                        <p style="font-size: 16px; color: #044281; margin:0;">
                                            Total en esta orden:
                                        </p>
                                    </div>
                                    <div class="col-12 p-0">
                                        <p  class="poppins-bold"
                                            style="color: #044281; font-size: clamp(24px, 2vw,35px);">
                                            <sup style="font-size: clamp(14px, 1.5vw,28px);">
                                                MXN $
                                            </sup> 
                                            {{ subtotal ?? 0 }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xxl-6 px-0">
                                <button @click="show_modal = true"
                                    class="btn text-white pills p-0"
                                    id="process-btn"
                                    :disabled="product_table_data <= 0"
                                    style="background: #198754; width: clamp(70px, 18vw,350px); height: clamp(50px, 3vw,90px); font-size: clamp(16px, 1.5vw,30px);"
                                    type="button">
                                        Procesar orden
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <!----Modal---->   
        <ki-modal v-if="show_modal">
            <div class="row bg-white p-3 position-relative" style="border-radius: 10px; width: 500px;">    
                <button
                        @click="show_modal = false"
                        class="btn btn-white position-absolute p-0 m-0"
                        style="right: -10px; top: -10px;box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); width: 25px; height: 25px;"
                        type="button">
                        X
                </button>
               
                <div class="col-12">
                    <p v-if="!set_date" class="text-dark">
                        Procesar orden de compra
                    </p>
                    <p v-if="set_date" class="text-dark">
                        Datos de entrega
                    </p>
                </div>

                <div class="col-12">
                    <ki-stepper
                        :steps="[1,2]"
                        :current_step="current_step"/>
                </div>

                <div class="col-12 bg-lightgray py-3" style="border-radius: 10px;">
                    <ki-input v-if="!set_date" label="Fecha del pedido:"
                        :value="new Date().toLocaleDateString()"
                        type="text"
                        :disabled="true"
                        full>
                    </ki-input>
                    <ki-input v-if="set_date" label="Términos de entrega:"
                        placeholder="ingrese términos de entrega"
                        v-model="delivery_terms"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="set_date && error.terms">
                        {{ error.terms}}
                    </span>
                </div>

                <div class="col-12 bg-lightgray my-2 py-3" style="border-radius: 10px;">

                    <ki-input v-if="!set_date" label="Fecha de entrega requerida:"
                        type="date"
                        :min="currentDate"
                        v-model="delivery_date"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="!set_date && error.delivery">
                        {{ error.delivery}}
                    </span>

                    <ki-select v-if="set_date" label="Términos de pago:"
                        :hidden_option="'Seleccione'"
                        :options="[{id: 1, label: 'DEBITO'}, {id: 2, label: 'CREDITO'}, {id: 3, label: 'EFECTIVO'}]"
                        v-model="id_transaction"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="set_date && error.transaction">
                        {{ error.transaction}}
                    </span>

                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button v-if="!set_date"
                        @click="show_modal = false"
                        class="btn btn-white pills mr-3"
                        type="button">
                        Cancelar
                    </button>
                    <button v-if="set_date"
                        @click="set_date = false; current_step = 1"
                        class="btn btn-white pills mr-3"
                        type="button">
                        Volver
                    </button>

                    <button v-if="!set_date"
                        @click="validateDate"
                        class="btn btn-primary pills text-white"
                        type="button">
                        Continuar
                    </button>
                    <button v-if="set_date"
                        @click="confirmOrder"
                        class="btn btn-primary pills text-white"
                        type="button">
                        Guardar
                    </button>

                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="clear_modal">
            <div class="row bg-white position-relative text-dark p-3" style="border-radius: 10px">
                <button @click.stop="(clear_modal = false)"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                    type="button">
                    X
                </button>
                <div class="col-12 text-center">
                    <p>
                        ¿Esta seguro de limpiar todos los datos?
                    </p>
                    <span>
                        Esta acción no puede revertida
                    </span>
                </div>

                <div class="d-flex col-12 p-3 justify-content-center">
                    <button @click.stop="(clear_modal = false)"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="clearData"
                        class="btn btn-danger text-white pills ml-2"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="resume_modal">
            <div class="row bg-white position-relative" style="border-radius: 10px">
                <button @click.stop="resume_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 25px; height: 25px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                    type="button">
                    X
                </button>

                <div class="col-12 text-dark  pl-2 py-2">
                    <p class="opensans-bold">
                        Resumen de la orden de compra
                    </p>
                </div>


                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3 pt-2" style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-bold">
                                Proveedor:
                            </p>
                            <span class="ml-2">
                                {{ resume.provider }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-bold">
                                Productos: 
                            </p>
                            <span class="ml-2">
                                {{ product_table_data.length}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-bold">
                                Fecha de pedido: 
                            </p>
                            <span class="ml-2">
                                {{ new Date().toLocaleString() }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-bold">
                                Fecha de entrega: 
                            </p>
                            <span class=ml-2>
                                {{ resume.delivery_date }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3 pb-2" style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-bold">
                                Forma de pago: 
                            </p>
                            <span class="ml-2">
                                {{  resume.transaction_type }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-bold">
                                Terminos: 
                            </p>
                            <span class="ml-2">
                                {{ resume.comments}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 text-dark my-2 opensans-bold">
                    <p>
                        Productos en la orden:
                    </p>
                </div>

                <div class="col-12">
                    <ki-table :data="product_table_data"
                        :allow_headers="resume_table_headers"
                        no_pagination color_body="white" color_headers="white">
                        <template v-slot:Borrar="data">
                            <button @click.stop="removeProduct(data.item)"
                                class="btn btn-danger text-white p-0 mb-2"
                                style="width: 25px; height: 25px;"
                                type="button">
                                X
                            </button>
                        </template>
                    </ki-table>
                </div>

                <div class="col-12 pt-2">
                    <div class="row">

                        <div class="col-2 opensans-semibold">
                            <ki-input label="subtotal:"
                                type="number"
                                v-model="subtotal"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-2 opensans-semibold">
                            <ki-input label="Importe:"
                                type="number"
                                v-model="taxes"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-2 opensans-semibold">
                            <ki-input label="Total:"
                                type="number"
                                v-model="subtotal"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>

                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end p-3">
                    <button @click.stop="cancel_order"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="processOrder"
                        class="btn btn-primary text-white pills ml-2"
                        type="button">
                        Procesar
                    </button>
                </div>
                
            </div>
        </ki-modal>

        <ki-modal v-if="historial_modal">
            <div class="row bg-white position-relative p-3 text-center" style="border-radius: 15px;">
                <button @click.stop="historial_modal = false"
                        class="btn btn-white p-0 position-absolute"
                        style="width: 25px; height: 25px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                        type="button">
                    X
                </button>

                <div class="col-12 mb-3">
                    <div style="height: 250px; overflow-y: scroll">
                        <ki-table :data="orders"
                                  :allow_headers="historial_headers"
                                  no_pagination color_body="white"
                                  border_headers="primary"
                                  color_headers_text="white"
                                  color_headers="primary">
                            <template v-slot:order_status="data">
                                {{ data.item.order_status === 'delivered' ? 'Entregado' : 'En espera' }}
                            </template>
                        </ki-table>
                    </div>
                </div>


                <div class="col-12">
                    <button @click.stop="historial_modal = false;"
                            class="btn btn-white pills"
                            type="button">
                        Cerrar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
    import less_icon from 'ASSETS/icons/resta.svg'
    import add_icon from 'ASSETS/icons/add.svg'
    import trash_icon from 'ASSETS/icons/trash-can.svg'
    import historial_icon from 'ASSETS/icons/historial.svg'
    import edit_icon from 'ASSETS/icons/icon_write.png'
    import add_icon_blue from 'ASSETS/icons/plus-blue-icon.png'

    import { mapState, mapGetters } from 'vuex'

    export default {
        name: 'ki-buyorder',
        data() {
            return {
                less_icon,
                add_icon,
                trash_icon,
                historial_icon,
                edit_icon,
                add_icon_blue,
                resume_modal: false,
                historial_modal: false,
                set_date: false,
                show_modal: false,
                clear_modal: false,
                product_table_headers: [
                    {key: 'quantity', label: 'Cantidad'},
                    {key: 'provider', label: 'Provedor'},
                    {key: 'product', label: 'Producto'},
                    {key: 'cost', label: 'Costo'},
                    {key: 'amount', label: 'importe', widt: 100},
                    {key: 'Borrar', width: '50'}
                ],
                product_table_data: [
                ],
                resume_table_headers: [
                    {key: 'quantity', label: 'Cantidad'},
                    {key: 'product', label: 'Producto'},
                    {key: 'cost', label: 'Costo'},
                    {key: 'amount', label: 'importe', widt: 100},
                    {key: 'Borrar', width: '50'}
                ],
                historial_headers: [
                    {key: 'id_purchase_order', label: '#'},
                    {key: 'vendor_name', label: 'Proveedor'},
                    {key: 'delivery_conditions', label: 'Condiciones de entrega'},
                    {key: 'order_status', label: 'Estado'},
                    {key: 'total', label: 'Total'},
                ],
                providers_options: [],
                products_options: [],
                transaction_types_options: [],
                search_query: null,
                provider_id: null,
                order_date: null,
                delivery_date: null,
                delivery_terms: null,
                payment_terms: null,
                other_specifications: null,
                product_id: null,
                id_transaction: null,
                quantity: null,
                cost: null,
                amount: null,
                subtotal: null,
                taxes: null,
                current_step: 1,
                all_products: null,
                all_orders: null,
                total: null,
                product_code: null,
                loading: true,
                setted_provider: null,
                error: {
                    product_id: null,
                    quantity: null,
                    provider_id: null,
                    cost: null,
                    amount: null,
                    delivery: null,
                    transaction: null,
                    terms: null,
                    product_code: null
                },
                resume: {
                    provider: null,
                    date: null,
                    delivery_date: null,
                    transaction_type: null,
                    comments: null,
                    subtotal: null,
                    total: null,
                    taxes: null,
                    products: []
                },
                
            };
        },
        computed: {
            ...mapState(['show_label']),
            ...mapGetters( {
                products: 'products_module/getProducts',
                providers: 'providers/getProviders',
                transaction_types: 'transaction_types/getTransactions',
                search_result: 'buy_order_module/getSearchResult',
                orders: 'buy_order_module/getOrders',
            }),
            currentDate () {
                const raw_date = new Date().toISOString();
                const date = raw_date.slice(0,10)
                return date
            }
        },
        methods: {
            checkProduct(product_id){
                const product = this.all_products.find( element => element.id_product == product_id)
                this.product_id = product_id
                this.cost = product.unit_cost
                this.amount = Number((this.cost * 0.16).toFixed(2)) 
            },
            clearData() {
                this.product_table_data = []
                this.search_query = null
                this.provider_id = null
                this.order_date = null
                this.delivery_date = null
                this.delivery_terms = null
                this.payment_terms = null
                this.other_specifications = null
                this.product_id = null
                this.id_transaction = null
                this.quantity = null
                this.cost = null
                this.amount = null
                this.subtotal = null
                this.taxes = null
                this.current_step = 1
                this.all_products = null
                this.all_orders = null
                this.total = null
                this.product_code = null
                this.setted_provider = null
                this.show_modal = false
                this.clear_modal = false
                this.resume_modal = false

                for(let k in this.errors) {
                    this.errors[k] = null
                }

                for(let k in this.resume) {
                    if(k === 'products' ) {
                        this.resume[k] = [];
                    }
                    else {
                        this.resume[k] = null;
                    }

                }
            },
            addProduct() {

                for( let error in this.error) {
                    this.error[error] = null
                }

                
                let complete = true

                if(_.isNull(this.product_id)) {
                    complete = false
                    this.error.product_id = '*Campo obligatorio'
                }

                if(_.isNull(this.provider_id)) {
                    complete = false
                    this.error.provider_id = '*Campo obligatorio'
                }

                if(this.setted_provider !== null && this.setted_provider != this.provider_id) {
                    this.error['provider_id'] = '*Solo un proveedor por orden de compra'
                    complete = false
                }

                if(_.isNull(this.cost)) {
                    complete = false
                    this.error.cost = '*Campo obligatorio'
                }

                if(_.isNull(this.amount)) {
                    complete = false
                    this.error.amount = '*Campo obligatorio'
                }

                if(_.isNull(this.quantity)) {
                    complete = false
                    this.error.quantity = '*Campo obligatorio'
                    return
                }

                if(!complete) return

                let provider = this.providers_options.find( element => element.id == this.provider_id)
                
                let product = this.all_products.find( element => element.id_product == this.product_id)

                let item = {
                    id: this.product_id,
                    quantity: this.quantity,
                    provider: provider.label,
                    product: product.product_name,
                    cost: (Number(product.unit_cost) * Number(this.quantity)).toFixed(2),
                    amount: (this.amount * this.quantity).toFixed(2),
                    Borrar: '-'
                }

                this.product_table_data.push(item)

                this.total = this.product_table_data.reduce((acc, item) => {
                    acc = Number(acc) + Number(item.cost)
                    acc = Number(acc) + Number(item.amount)
                    return acc.toFixed(2)

                }, 0)

                this.subtotal = this.product_table_data.reduce((acc, product) => {
                        acc = Number(acc) + Number(product.cost)
                        return acc.toFixed(2)
                    }, 0)

                this.taxes = this.product_table_data.reduce((acc, product) => {
                    acc = Number(acc) + Number(product.amount)
                    return acc.toFixed(2)
                }, 0)

                this.product_code = null
                this.product_id = null
                this.quantity = null
                this.cost = null
                this.amount = null
            },
            removeProduct(product){
                let index = this.product_table_data.indexOf(product)
                delete this.product_table_data[index]
                this.product_table_data = this.product_table_data.filter(item => true)

                this.total = this.product_table_data.reduce((acc, item) => {

                    acc = Number(acc) + Number(item.amount)
                    return acc.toFixed(2)

                }, 0)

                this.subtotal = this.product_table_data.reduce((acc, product) => {
                        acc = Number(acc) + Number(product.cost)
                        return acc.toFixed(2)
                    }, 0)

                this.taxes = this.product_table_data.reduce((acc, product) => {
                    acc = Number(acc) + Number(product.amount)
                    return acc.toFixed(2)
                }, 0)

                if(this.product_table_data.length <= 0) {
                    this.resume_modal = false
                    this.setted_provider = null
                }
            },
            setProducts(provider_id) {
                

                let products = this.all_products.filter( product => product.id_vendor == provider_id)
                this.products_options = products.map( product => {
                    return { id: product.id_product, label: product.product_name}
                })
                
                this.provider_id = provider_id
                this.product_id = null

                if(this.setted_provider === null) {
                    this.setted_provider = provider_id
                }

            },
            validateDate() {
                this.error['delivery'] = null
                if(_.isNull(this.delivery_date) || _.isEmpty(this.delivery_date)) {
                    this.error['delivery'] = '*Campo obligatorio'
                    return
                }
                else {
                    this.set_date = true
                    this.current_step = 2
                }
            },
            confirmOrder() {

                this.error['transaction'] = null
                this.error['terms'] = null

                let complete = true

                if(_.isNull(this.id_transaction) || _.isEmpty(this.id_transaction)) {
                    this.error['transaction'] = '*Campo obligatorio'
                    complete = false
                }


                if(complete) {
                    let provider = this.providers_options.find( provider => provider.id == this.provider_id)
                    let transaction_type = this.transaction_types_options.find( transaction => transaction.id == this.id_transaction)

                    this.resume.provider = provider.label
                    this.resume.date = this.order_date
                    this.resume.delivery_date = this.delivery_date
                    this.resume.transaction_type = transaction_type.label
                    this.resume.comments = this.delivery_terms
                    this.show_modal = false
                    this.resume_modal = true
                    
                }
            },
            async processOrder() {
                if(this.product_table_data.length <= 0) return

                let selected_products = this.product_table_data.map( product => {
                        return { id_product: Number(product.id), quantity: Number(product.quantity)}
                    })

                let payload = {
                    id_vendor: Number(this.provider_id),
                    id_transaction_type: Number(this.id_transaction),
                    product_list: selected_products,
                    subtotal: Number(this.subtotal),
                    total: Number(this.subtotal),
                    tax: Number(this.taxes),
                    delivery_date: this.delivery_date,
                    delivery_conditions: this.delivery_terms,
                    shipment_cost: 0,
                    shipment_taxes_cost: 0,
                    comments: this.other_specifications
                }

                await this.$store.dispatch('buy_order_module/addOrder', payload)
                this.setted_provider = null
                this.clearData()

            },
            async searchProductByCode() {
                this.error.product_code = null

                if(_.isNull(this.product_code) || _.isEmpty(this.product_code)) {
                    this.error['product_code'] = '*Introducir código'
                    return
                }

                await this.$store.dispatch('buy_order_module/searchProduct', { upc: this.product_code})

                if(this.search_result.not_found) {
                    this.error['product_code'] = '*Producto no encontrado'
                }

                else {
                    this.setProducts(this.search_result.id_vendor)
                    setTimeout(() => {
                        this.checkProduct(this.search_result.id_product)
                        // this.provider_id = this.search_result.id_vendor
                        // this.product_id = String(this.search_result.id_product)
                        
                    }, 100)
                   
                }
                

            }
        },
        async mounted(){
            try{

                //providers
                await this.$store.dispatch('providers/listProviders', {datatable: false})
                this.providers_options = this.providers.map( item => {
                    return { id: item.id_vendor, label: item.vendor_name}
                })

                //products
                await this.$store.dispatch('products_module/listProducts', {datatable: false})
                this.all_products = this.products
                //console.log('all',this.all_products)

                //transactions
                await this.$store.dispatch('transaction_types/listTransactionsTypes', {datatable: false})
                this.transaction_types_options = this.transaction_types.map( transaction => {
                    return {id: transaction.id_transaction_type, label: transaction.transaction_name}
                })

                //order historial
                await this.$store.dispatch('buy_order_module/listOrders', { datatable: false,})

                this.loading = false

            }

            catch(error){
                console.log(error)
            }
        }
    };
</script>

<style lang="scss" scoped>

    @mixin upTo($size) {
        @media (max-width: $size) {
            @content
        }
    }

    #Inventory {
        @include upTo(1127px) {
            #hty-btn {
                transform: scale(0.8);
            }

            #rpt-btn {
                transform: scale(0.8);
            }

            #clear-btn {
                transform: scale(0.8);
            }
            #process-btn {
                width: 50px;
                background: red;
            }
        }
        
    }
</style>